import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from 'environment';
import { Observable, Subject, from, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, switchMap, take } from 'rxjs/operators';
import { CONSTANTS } from '@shared/constants';

import { AuthSmlService } from '../auth-sml/auth-sml.service';
import { AuthService } from '../auth/auth.service';
import { SessionService } from '../session/session.service';
import { TrackingService } from '../tracking/tracking.service';
import { RequestOptions } from './request-options.interface';

@Injectable()
export class APIService {
  public responseErrorStatus$: Subject<number> = new Subject<number>();
  private _isAuthenticating = false;

  constructor(
    private _http: HttpClient,
    private _trackingService: TrackingService,
    private _sessionService: SessionService,
    private _authService: AuthService,
    private _authSmlService: AuthSmlService
  ) {}

  public get(url: string, userDefinedOptions?: RequestOptions): Observable<any> {
    return this._doRequest('get', userDefinedOptions, url);
  }

  public post(url: string, body: any, userDefinedOptions?: RequestOptions): Observable<any> {
    return this._doRequest('post', userDefinedOptions, url, body);
  }
  public put(url: string, body: any, userDefinedOptions?: RequestOptions): Observable<any> {
    return this._doRequest('put', userDefinedOptions, url, body);
  }
  public patch(url: string, body: any, userDefinedOptions?: RequestOptions): Observable<any> {
    return this._doRequest('patch', userDefinedOptions, url, body);
  }
  public delete(url: string, userDefinedOptions?: RequestOptions): Observable<any> {
    return this._doRequest('delete', userDefinedOptions, url);
  }

  private _doRequest(requestType: string, userDefinedOptions?: RequestOptions, ...requestArgs: any[]): Observable<any> {
    return this._buildRequest(requestType, userDefinedOptions, ...requestArgs).pipe(
      take(1),
      catchError((error) => {
        const responseUnauthorized: boolean = this._isUnauthorizedError(error.status);
        if (responseUnauthorized) {
          return this._catchSessionExpired(requestType, userDefinedOptions, ...requestArgs);
        } else {
          return this._responseErrorHandler(error);
        }
      }),
      mergeMap((value) => this._responseSuccessHandler(value))
    );
  }

  private _buildRequest(requestType: string, userDefinedOptions?: RequestOptions, ...requestArgs: any[]): Observable<any> {
    const options: RequestOptions = this._mergeRequestOptions(userDefinedOptions);
    return this._http[requestType](...requestArgs, options);
  }

  private _mergeRequestOptions(userDefinedOptions: RequestOptions): RequestOptions {
    const userOptions: RequestOptions = userDefinedOptions ? userDefinedOptions : {};
    const params: HttpParams = userOptions.params ?? new HttpParams();
    const userHeaders: HttpHeaders = userOptions.headers ? userOptions.headers : new HttpHeaders();
    const headers = userOptions.userDefinedOptionsOnly ? userHeaders : this._mergeOptionKeys(this._requestHeaders, userHeaders);
    return Object.assign({}, this._requestOptions, userDefinedOptions, { params, headers });
  }

  private _mergeOptionKeys(httpOption: HttpParams | HttpHeaders, userOption: HttpParams | HttpHeaders): HttpParams | HttpHeaders {
    let optionMerge: HttpParams | HttpHeaders | any = httpOption;
    for (const key of userOption.keys()) {
      optionMerge = optionMerge.set(key, userOption.get(key));
    }

    return optionMerge;
  }

  private get _requestOptions(): RequestOptions {
    const params: HttpParams = new HttpParams();
    const headers: HttpHeaders = this._requestHeaders;
    return { params, headers, withCredentials: false, observe: 'response' };
  }

  private get _requestHeaders(): HttpHeaders {
    const bearerAuthToken = ENVIRONMENT.isMiuEnvironment ? this._authService.bearerAuthToken : this._authSmlService.bearerAuthToken;

    return new HttpHeaders().set('Authorization', bearerAuthToken);
  }

  private _responseSuccessHandler(response: HttpResponse<any> | HttpErrorResponse): Observable<any> {
    const json = this._responseJson(response);
    const responseSuccess: boolean = this._isSuccessStatus(response.status);
    return responseSuccess ? of(json) : this._responseErrorHandler(response);
  }

  private _responseJson(response: HttpResponse<any> | HttpErrorResponse): any {
    return response && response['body'] ? response['body'] : undefined;
  }

  private _isSuccessStatus(status: number): boolean {
    return status >= 200 && status < 300;
  }

  private _isUnauthorizedError(status: number): boolean {
    const unauthorizedStatus: number[] = [401];
    return unauthorizedStatus.indexOf(status) > -1;
  }

  private _responseErrorHandler(error: HttpResponse<any> | HttpErrorResponse): Observable<any> {
    if (!(error.url.includes(CONSTANTS.analysisUrl.validate) && error.status === 504)) {
      this._broadcastErrorStatus(error.status);
    }
    return throwError(error);
  }

  private _refreshSessionHeader(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this._isAuthenticating) {
        this._isAuthenticating = true;
        this._sessionService.refreshSession().then(() => {
          this._isAuthenticating = false;
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  private _catchSessionExpired(requestType: string, userDefinedOptions: RequestOptions, ...requestArgs: any[]): Observable<any> {
    return from(this._refreshSessionHeader()).pipe(
      delay(1000),
      switchMap(() => this._buildRequest(requestType, userDefinedOptions, ...requestArgs)),
      take(5)
    );
  }

  private _broadcastErrorStatus(status: number): void {
    this.responseErrorStatus$.next(status);
  }
}
